<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row no-gutters>
        <b-col md="2">
          <b-card class="top-cards">
            <b-row no-gutters>
              <b-col md="4">
                <div class="icon-container">
                  <i class="fas fa-user-friends icon"></i>
                </div>
              </b-col>
              <b-col md="8">
                <div class="text">
                  <p class="text-heading">Total Users</p>
                  <p class="value">{{ totalUsers }}</p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="2">
          <b-card class="top-cards">
            <b-row no-gutters>
              <b-col md="4">
                <div class="icon-container">
                  <i class="fas fa-layer-group icon"></i>
                </div>
              </b-col>
              <b-col md="8">
                <div class="text">
                  <p class="text-heading">Total Projects</p>
                  <p class="value">{{ totalProjects }}</p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="2">
          <b-card class="top-cards">
            <b-row no-gutters>
              <b-col md="4">
                <div class="icon-container">
                  <i class="fas fa-mountain icon"></i>
                </div>
              </b-col>
              <b-col md="8">
                <div class="text">
                  <p class="text-heading">Total Acres</p>
                  <p class="value">{{ totalLands }}</p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="2">
          <b-card class="top-cards">
            <b-row no-gutters>
              <b-col md="4">
                <div class="icon-container">
                  <i class="fas fa-tree icon"></i>
                </div>
              </b-col>
              <b-col md="8">
                <router-link class="tree-view-link" to="/tree-view">
                  <div class="text">
                    <p class="text-heading">Total Plants</p>
                    <p class="value">{{ totalPlants }}</p>
                  </div>
                </router-link>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="2">
          <b-card class="top-cards">
            <b-row no-gutters>
              <b-col cols="4">
                <div class="icon-container">
                  <i class="fas fa-wind icon"></i>
                </div>
              </b-col>
              <b-col class="8">
                <div class="text">
                  <p class="text-heading">Carbon Footprint</p>
                  <p class="value">{{ carbonFootprint }}</p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="2">
          <b-card class="top-cards">
            <b-row no-gutters>
              <b-col cols="4">
                <div class="icon-container">
                  <i class="fas fa-wave-square icon"></i>
                </div>
              </b-col>
              <b-col class="8">
                <div class="text">
                  <p class="text-heading">Total Activities</p>
                  <p class="value">{{ totalActivities }}</p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- 3rd row -->
      <b-row class="table-data">
        <b-col md="12" class="mb-4">
          <active-user-chart />
        </b-col>
        <b-col md="4">
          <b-card class="recent-users">
            <b-card-title class="title">Recently Active Users</b-card-title>
            <b-card-body v-for="(user, index) in users" :key="index">
              <b-row no-gutters>
                <b-col cols="3 text-center">
                  <i
                    class="fas fa-user-circle user-icon"
                    aria-hidden="true"
                  ></i>
                </b-col>
                <b-col cols="5">
                  <p class="name">{{ user.name }}</p>
                  <p class="contact">{{ user.phone }}</p>
                </b-col>
                <b-col cols="4 text-center">
                  <router-link :to="'/users/' + user._id"
                    ><i class="fas fa-eye eye-icon" aria-hidden="true"></i
                  ></router-link>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="8">
          <b-table
            class="dataTable rounded-left"
            :outlined="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            :caption-top="true"
            :busy="isBusy"
          >
            <template #cell(SL_no.)="data">
              {{ data.index + 1 }}
            </template>
            <template #table-caption>Recent Projects</template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ActiveUserChart from '../components/charts/ActiveUserChart.vue';

export default {
  components: { ActiveUserChart },
  name: 'Home',

  data() {
    return {
      isBusy: false,
      fields: ['SL_no.', 'name', 'code'],
      titles: ['Recent Users'],
      sortBy: 'createdAt',
      sortDesc: true,
      totalUsers: 0,
      totalProjects: 0,
      totalPlants: 0,
      totalLands: 0,
      totalActivities: 0,
      users: [],
      items: [],
    };
  },

  mounted() {
    this.fetchData();
    this.getCardDetails();
    this.getRecentProjects();
  },
  computed: {
    carbonFootprint() {
      let carbonFootprint = this.totalLands * 37.5;
      return carbonFootprint.toFixed(2);
    },
  },

  methods: {
    getCardDetails() {
      window.axios.get('dashboard/card-details').then((resp) => {
        console.log('Card data' + resp.data.data);
        this.totalUsers = resp.data.data[2].count;
        this.totalProjects = resp.data.data[3].count;
        this.totalLands = resp.data.data[0].count;
        this.totalPlants = resp.data.data[1].count;
        this.totalActivities = resp.data.data[4].count;
      });
    },

    getRecentProjects() {
      window.axios.get('project').then((resp) => {
        this.items = resp.data.data.slice(0, 10);
      });
    },

    fetchData() {
      this.isBusy = true;
      this.$store
        .dispatch('fetchData', this.query)
        .then((res) => {
          this.isBusy = false;
          this.users = res.data.data.filter((item) => item.last_activity);
          this.users = this.users.sort(
            (a, b) => new Date(b.last_activity) - new Date(a.last_activity)
          );
          this.users = this.users.slice(0, 10);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style scoped>
.nav {
  margin-top: 40px;
  margin-left: 61px;
}

.heading {
  color: #888888;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  letter-spacing: 3.5%;
}

.stats {
  margin-top: 30px;
  margin-bottom: 30px;
}

.user-icon {
  height: 47px;
  width: 40px;
  color: #1da040;
}

.name {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  line-height: 21.09px;
  font-size: 18px;
}

.contact {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  line-height: 18.75px;
  font-size: 16px;
}

.eye-icon {
  width: 28px;
  height: 30.88px;
  color: #1da040;
}

.recent-users {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.user-circle {
  height: 45px;
  width: 47px;
}

.icon {
  height: 30px;
  width: 33px;
  color: #fff;
  background-color: #1ea041;
  margin: 35px 25px;
}

.icon-container {
  height: 102px;
  width: 80px;
  background-color: #1ea041;
  border-radius: 10px 0px 0px 10px;
}

.title {
  height: 64px;
  text-align: center;
  font-size: 22px;
  background-color: #dcffd9;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 3.5%;
  font-weight: 400;
  padding-top: 17px;
  padding-bottom: 17px;
}

.hidden_header {
  display: none;
}

.card-body {
  padding: 0px;
}

.top-cards {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  height: 102px;
  margin: 2px;
}

.text {
  padding-left: 14px;
}

.text-heading {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: #7c7c7c;
  margin-top: 10px;
  margin-bottom: 0;
  margin-right: 10px;
  text-align: right;
}

.value {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  letter-spacing: 3.5%;
  line-height: 52.5px;
  font-weight: 500;
  color: #1da040;
  margin-bottom: 25px;
  text-align: right;
  margin-right: 10px;
}

.table-data {
  margin-top: 32px;
}

.tree-view-link:hover {
  text-decoration: none;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 12px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

/deep/ .dataTable caption {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 25px;
}

/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}
</style>
