<template>
  <div>
    <b-card class="active-users">
      <template #header>
        <div class="d-flex justify-content-between">
          <h4 class="d-inline">Active Users</h4>
          <b-link to="/user-stats" class="text-right  text-decoration-none"
            >View More</b-link
          >
        </div>
      </template>
      <b-row no-gutters>
        <b-col md="9" class="mt-3">
          <apexchart
            height="330px"
            type="area"
            :options="options"
            :series="series"
          ></apexchart>
        </b-col>
        <b-col md="3">
          <b-row class="stats" no-gutters>
            <b-col cols="12">
              <p class="day">30 Days</p>
              <p class="active">
                {{ oneMonthActiveUsersCount }}
              </p>
            </b-col>
            <br />
            <b-col cols="12" class="border-cell">
              <p class="day">7 Days</p>
              <p class="active">
                {{ oneWeekActiveUsersCount }}
              </p>
            </b-col>
            <b-col cols="12">
              <p class="day">Today</p>
              <p class="active">
                {{ oneDay }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ActiveUserCount',
  data() {
    return {
      options: {
        noData: {
          text: 'Loading...',
        },
        chart: {
          id: 'vuechart-example',
        },
        colors: ['#1DA040'],
        title: {
          align: 'left',
          margin: 10,
          offsetX: 15,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Poppins',
            color: '#6D6D6D',
          },
        },
      },
      series: [],
      userData: [],
      oneWeekActiveUsersCount: 0,
      oneMonthActiveUsersCount: 0,
      activeUsers: null,
      activeUsersCount: null,
      activeUsersWithCount: null,
    };
  },

  computed: {
    oneDay() {
      return this.series[0].data[30].y;
    },
  },

  methods: {
    getUserStatsForMonth() {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      let endDate = new Date();
      window.axios
        .get('app-usage/statistics', {
          params: {
            end: endDate,
            start: startDate,
          },
        })
        .then((resp) => {
          this.oneMonthActiveUsersCount = resp.data.data.activeUsersCount;
        });
    },

    getUserStatsForWeek() {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      let endDate = new Date();
      window.axios
        .get('app-usage/statistics', {
          params: {
            end: endDate,
            start: startDate,
          },
        })
        .then((resp) => {
          this.oneWeekActiveUsersCount = resp.data.data.activeUsersCount;
        });
    },

    getUserData() {
      window.axios
        .get('dashboard/app-usage')
        .then((resp) => {
          this.userData = resp.data.data;
          this.series = [
            {
              data: this.userData,
            },
          ];
          console.log(this.series);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    fetchData() {
      this.$store
        .dispatch('fetchData', this.query)
        .then((res) => {
          const pastMonthToday = new Date();
          const tempActiveUsersArray = [];
          pastMonthToday.setMonth(pastMonthToday.getMonth() - 1);
          this.activeUsers = res.data.data.filter((item) => item.last_activity);
          for (let i = 0; i < this.activeUsers.length; i++) {
            const date = new Date(this.activeUsers[i].last_activity);
            if (date >= pastMonthToday) {
              tempActiveUsersArray.push(
                new Date(this.activeUsers[i].last_activity)
              );
            }
          }
          this.activeUsers = tempActiveUsersArray;
          this.activeUsers = this.activeUsers.sort((a, b) => a - b);
          this.activeUsersCount = this.activeUsers.length;
          this.getData();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getData() {
      const data = [];
      const counts = {};
      const activeUsersWithCount = [];
      for (let i = 0; i < this.activeUsers.length; i++) {
        data.push(
          `${this.activeUsers[i].getFullYear()}-${this.activeUsers[
            i
          ].getMonth() + 1}-${this.activeUsers[i].getDate()}`
        );
      }
      for (let i = 0; i < data.length; i++) {
        counts[data[i]] = 1 + (counts[data[i]] || 0);
      }
      for (const item in counts) {
        activeUsersWithCount.push({
          date: new Date(item),
          activeUserCount: counts[item],
        });
      }
      this.activeUsersWithCount = activeUsersWithCount;
    },
  },

  mounted() {
    this.getUserData();
    this.fetchData();
    this.getUserStatsForWeek();
    this.getUserStatsForMonth();
  },
};
</script>

<style scoped>
.active-users {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.card-body {
  margin: 0;
  padding: 0;
}

.stats {
  background-color: #ededed;
  height: 100%;
}

.day {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 3.5%;
  color: #8c8c8c;
  margin-left: 24px;
  font-weight: 400;
  margin-top: 10px;
}

.active {
  font-size: 38px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 3.5%;
  color: #454545;
  margin-left: 24px;
  font-weight: 500;
}

.border-cell {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

.card-header {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
